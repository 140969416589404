.hidden {
  display: none !important;
}

.animated-hide {
  transition: display ease-in-out 0.4s;
}

@media screen and (max-width: $md) {
  .hidden-md-down {
    display: none;
  }
}

.display-flex {
  display: flex;
}

.justify-center {
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
}

// @include box-distance-generator("padding", ".p", 1, 10);
// @include box-distance-generator("margin", ".m", 1, 10);
// @include box-distance-generator("margin-top", ".mt", 1, 10);
// @include box-distance-generator("margin-right", ".mr", 1, 10);
// @include box-distance-generator("margin-bottom", ".mb", 1, 10);
// @include box-distance-generator("margin-left", ".ml", 1, 10);
// @include box-distance-generator("padding-top", ".pt", 1, 10);
// @include box-distance-generator("padding-right", ".pr", 1, 10);
// @include box-distance-generator("padding-bottom", ".pb", 1, 10);
// @include box-distance-generator("padding-left", ".pl", 1, 10);
