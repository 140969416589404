html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  @media screen and (max-width: $sm) {
    font-size: 10pt;
  }

  @media screen and (min-width: $md) {
    font-size: 12pt;
  }
  @media screen and (min-width: $lg) {
    font-size: 14pt;
  }
}

.wrapper {
  position: relative;
  width: 1200px;
  height: 600px;
  margin: 0 auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#stage {
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer-links {
  position: absolute;
  width: 100%;
  bottom: -50px;
  text-align: center;

  a {
    color: #007f00;
    font-family: 'Arial', sans-serif;
    font-size: 10pt;
  }

  #yj-share-button {
    background-color: #acacac;
    color: white;
    font-family: 'Arial', sans-serif;
    font-size: 8.5pt;
    padding: 3px 1px;
    font-weight: bold;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
    }
    
    img {
      height: 10px;
      width: 10px;
      margin-right: 2px;
    }

    #share_button_activated {
      color: red;
    }
  }
}

#menubar {
  height: 5em;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  left: 0;
  z-index: 10;

  & > .menu-left {
    display: flex;
    flex-direction: row;

    & > img {
      height: 80px;
      margin-left: 15px;
      margin-top: 15px;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }
      
      &.volumeOff {
        opacity: 0.3;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  & > .menu-right {
    display: flex;
    flex-direction: row;

    &.inactive {
      opacity: 0.3;
      cursor: default;

      & > img:hover {
        opacity: 1;
        cursor: default;
      }
    }

    & > img {
      height: 80px;
      margin-right: 15px;
      margin-top: 15px;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
      }

      &.jokerUsed {
        opacity: 0.3;
        cursor: default;
      }
    }
  }
}

.view {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 100%;
  overflow: hidden;
}

// view_start

#view_start {
  background-image: url("./images/00_bp_bochum_quiz_Grafik-Hintergrund_Startbildschirm.png");
}

#view_start__quizmaster {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 88%;
  width: 33%;
  background: url("./images/00_bp_bochum_quiz_Garfik-Quizmaster_Startbildschirm.png");
  background-size: contain;
  background-repeat: no-repeat;
}

#view_start__startbutton {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & > img {
    max-height: 30%;
    cursor: pointer;
    margin-bottom: 2.5em;
    transition: all ease-in-out 0.15s;
    &:hover {
      opacity: 0.85;
    }
  }
}

// view_firstquestion

#view_firstquestion {
  background-image: url("./images/01_bp_bochum_quiz_Grafik-Hintergrund_Einstiegsfrage.png");
     
  & > #view_firstquestion_start_button {
    position: absolute;
    height: 120px;
    bottom: 12px;
    right: 30px;
    z-index: 50;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  & > #view_firstquestion_restart_button {
    position: absolute;
    height: 120px;
    bottom: 12px;
    right: 30px;
    z-index: 50;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  & > #firstquestion_sortedanswers {
      width: 100%;
      height: 48%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
      & > .sortedanswer {
        background-image: url("./images/box-orange.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 430px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: arial;
        font-size: 15pt;
      }
    }
    
    & > #firstquestioncontainer {
      width: 100%;
      height: 52%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 30;
      
      & > .firstquestion {
        background-image: url("./images/box-green.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 900px;
        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > strong {
          width: 80%;
          text-align: center;
          font-family: arial;
          font-size: 16pt;
        }
      }

      & > .firstquestion_answers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 100%;

        & > .firstquestion_answer {
          background-image: url("./images/box-green.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 430px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: arial;
          font-size: 15pt;
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            opacity: 0.85;
          }

          &.checkedAnswer {
            background-image: url("./images/box-green_checkedAnswer.svg");
          }
        }
      }
    }

    #view_firstquestion__quizmaster {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 550px;
      width: 300px;
      background-image: url("./images/01_bp_bochum_quiz_Grafik-Quizmaster_Einstiegsfrage.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      z-index: 20;
    }
  }

// view_questions

#view_questions {
  background-image: url("./images/04_bp_bochum_quiz_Grafik-Hintergrund_Fragestellung.png");
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > .container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;

    & > .container_left {
      position: absolute;
      width: 900px;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      & > .quizmastercontainer {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        & > #view_questions__quizmaster {
          background-image: url("./images/04_bp_bochum_quiz_Grafik-Quizmaster_Fragestellung.png");
          background-size: cover;
          background-repeat: no-repeat;
          height: 100%;
          width: 52%;
          position: relative;
          left: 160px;

          & > #colleague_joker {
            background-image: url("./images/bp_bochum_quiz_Grafik-Joker_Kollegen.png");
            width: 192px;
            height: 260px;
            background-size: cover;
            position: relative;
            top: 15px;
            right: 100px;
            min-width: 183px;
            min-height: 247px;

            & > #colleague_joker_content {
              width: 69%;
              height: 52%;
              position: relative;
              top: 34%;
              left: 16%;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: flex-end;

              & > #colleague_answerA {
                height: 50%;
                width: 17%;
                background-color: #fed420;
              }

              & > #colleague_answerB {
                height: 50%;
                width: 17%;
                background-color: #fed420;
              }

              & > #colleague_answerC {
                height: 50%;
                width: 17%;
                background-color: #fed420;
              }

              & > #colleague_answerD {
                height: 50%;
                width: 17%;
                background-color: #fed420;
              }
            }
          }
        }

        & > #quizmaster_correctAnswer {
          background-image: url("./images/bp_bochum_quiz_Grafik-Quizmaster_RichtigeAntwort.png");
          background-size: cover;
          background-repeat: no-repeat;
          height: 95%;
          width: 80%;
          z-index: 20;

          & > #quizmaster_correctAnswerText {
            position: relative;
            top: 45px;
            left: 30px;
            width: 300px;
            font-family: arial;
            font-size: 10pt;

            &.text_medium {
              font-size: 12pt;
            }

            &.text_large {
              font-size: 14pt;
            }
          }
        }

        & > #quizmaster_expert {
          background-image: url("./images/bp_bochum_quiz_Grafik-Quizmaster_Experten-Joker.png");
          background-size: cover;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;

          & > #quizmaster_expertText {
            position: absolute;
            font-family: arial;
            top: 40px;
            width: 215px;
            left: 335px;
            font-size: 12pt;
          }
        }

        & > #final_score_box {
          position: absolute;
          width: 200px;
          top: 120px;
          left: 20px;
          border: 10px solid #94c11f;
          background-color: white;
          border-radius: 10px;
          padding: 20px;

          & > span {
            font-family: arial;
            font-size: 21pt;

            & > strong {
              color: #038d3a;
            }
          }

          & img {
            position: absolute;
            height: 120px;
            right: -75px;
            top: 10px;

            cursor: pointer;

            &:hover {
              opacity: 0.85;
            }
          }
        }
      }

      & > #questioncontainer {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -15px;

        & > .question {
          background-image: url("./images/box-green.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 900px;
          height: 165px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > strong {
            width: 80%;
            text-align: center;
            font-family: arial;
            font-size: 20pt;
          }
        }

        & > .answers {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          font-size: 16pt;
          max-width: 100%;

          .text_small {
            font-size: 10pt !important;
          }

          .text_medium {
            font-size: 12pt !important;
          }

          & > .answer {
            background-image: url("./images/box-green.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 450px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: arial;
            cursor: pointer;

            &:hover {
              opacity: 0.85;
            }

            & strong {
              width: 75%;
            }

            &.hideAnswerText {
              cursor: default;

              &:hover {
                opacity: 1;
              }

              & span {
                display: none;
              }
            }
          }
        }
      }
    }

    & .container_right {
      position: absolute;
      right: 0;
      height: 100%;
      width: 280px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > #next_question_button {
        position: absolute;
        width: 200px;
        top: 26px;
        right: 20px;
        border: 10px solid #94c11f;
        background-color: white;
        border-radius: 10px;
        text-align: center;
        font-family: arial;
        font-size: 18pt;
        padding: 15px;
        cursor: pointer;
        z-index: 30;

        &:hover {
          opacity: 0.85;
        }
      }

      & #view_question__scorebox {
        position: absolute;
        height: 400px;
        width: 200px;
        bottom: 20px;
        right: 20px;
        border: 10px solid #94c11f;
        background-color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 15px;

        & .score {
          font-family: arial;
          font-size: 17pt;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &.active {
            background-color: #ee752e;
            color: white;
          }

          & > .questionnumber {
            margin-left: 10px;
            font-weight: bold;
          }

          & > .points {
            min-width: 55%;
          }
        }
      }
    }
  }

  & > #confetti_canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

// view_explanation

#view_explanation {
  background-image: url("./images/03_bp_bochum_quiz_Grafik-Hintergrund_IconErklaerung.png");
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

#view_explanation__box {
  border: 10px solid #94c11f;
  background-color: white;
  border-radius: 10px;
  height: 430px;
  width: 270px;
  bottom: 35px;
  position: relative;
  z-index: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  font-family: arial;

  & > .box_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 13px;

    & > img {
      height: 75px;
      margin-right: 20px;
    }

    & > .description {
      display: flex;
      flex-direction: column;

      & > .title {
        font-weight: bold;
        color: #0b9548;
        font-size: 14pt;
        margin-bottom: 5px;
      }

      & > .text {
        font-size: 9pt;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
      }
    }
  }
}

#view_explanation__quizmaster {
  margin-left: -5px;
  z-index: 2;

  & > img {
    max-height: 100%;
    height: 580px;
    width: auto;
  }
}

#view_explanation__startbutton {
  position: absolute;
  bottom: 20px;
  right: 20px;

  & > img {
    max-height: 100%;
    height: 190px;
    width: auto;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }
}

#congratulations {
  font-family: arial;
  font-size: 30pt;
  position: absolute;
  top: 350px;
  text-align: center;
  background-color: white;
  border: 10px solid #94c11f;
  border-radius: 10px;
  padding: 20px;
}

#quizmaster_wrongAnswer,
#firstquestion_quizmaster_wrongAnswer{
  background-image: url("./images/06_bp_bochum_quiz_Grafik-Quizmaster_FalscheAntwort.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45%;
  height: 98%;

  #quizmaster_wrongAnswerText,
  #firstquestion_quizmaster_wrongAnswerText{
    position: relative;
    top: 30px;
    left: 25px;
    width: 230px;
    font-family: arial;
    font-size: 13pt;
  }
}

#firstquestion_final_score_box {
  position: absolute;
  width: 200px;
  top: 120px;
  left: 20px;
  border: 10px solid #94c11f;
  background-color: white;
  border-radius: 10px;
  padding: 20px;

  & > span {
    font-family: arial;
    font-size: 21pt;

    & > strong {
      color: #038d3a;
    }
  }

  & img {
    position: absolute;
    height: 120px;
    right: -75px;
    top: 10px;

    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }
}


.notClickable {
  cursor: default !important;

  &:hover {
    opacity: 1 !important;
  }
}

.correctAnswer {
  background-image: url("./images/box-green_richtigeAntwort.svg") !important;
  color: #fff !important;
}

.wrongAnswer {
  background-image: url("./images/box-green_falscheAntwort.svg") !important;
}

#share_button_activated {
  color: grey;
  margin-left: 10px;
  font-family: sans-serif;
  font-size: 10pt;
}